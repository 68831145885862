<template>
  <div class="content-wrapper">
    <bo-page-title />
    <div class="content pt-0">
      <b-card v-if="isList" no-body>
        <b-card-header>
          <b-row>
            <b-col lg="8">
              <b-form-row>
                <b-col lg="4">
                  <b-form-group>
                    <v-select placeholder="Pilih Status" @input="doFill" v-model="filter.status" :options="Config.mr.StatusOptions" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-col>
            <b-col lg="4">
              <SearchInput :value.sync="filter.search" @search="doFill" />
            </b-col>
          </b-row>
        </b-card-header>
        <b-card-body class="p-0">
        <b-table
          :fields="fields"
          :items="dataList||[]"
          :per-page="perPage"
          :primary-key="idKey"
          :busy="!dataList"
          responsive
          show-empty
          striped
        >
          <template #empty>
            <div class="text-center">
              <h4 align="center"><span v-if="filter.status || filter.search">Hasil pencarian tidak ditemukan</span>
              <span v-else>Belum ada data {{ pageTitle }} yang terdaftar</span></h4>
            </div>
          </template>
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(number)="v">
            {{(data.per_page*(data.current_page-1))+v.index+1}}
          </template>
          <template
            #cell(mp_is_active) = "data"
          >
            <b-badge
              v-if="data.value=='Y'"
              variant="success"
            >Aktif</b-badge>
            <b-badge
              v-else
              variant="danger"
            >Nonaktif</b-badge>
          </template>
          <template
            #cell(action)="data"
          >
            <div class="list-icons">
              <router-link class="list-icons-item"
                v-if="moduleRole('edit')"
                :to="{name: $route.name, params: {pageSlug: data.item[idKey]}}"
                data-toggle="tooltip" data-placement="top" title="View"><i
                  class="icon-pencil5"></i></router-link>
              <a href="javascript:;" class="list-icons-item"
                v-if="moduleRole('delete')"
                @click="doDelete(data.index, data.item)"
                data-toggle="tooltip" data-placement="top" title="Delete"><i
                  class="icon-bin"></i></a>
              <a href="javascript:;" class="list-icons-item"
                v-if="moduleRole('changeStatus')"
                @click="doChangeStatus(data.index, data.item)"
                data-toggle="tooltip" data-placement="top" title="Change Status"><i
                  class="icon-cog6"></i></a>
            </div>
            <span v-if="!(moduleRole('delete') || moduleRole('changeStatus') || moduleRole('edit'))">-</span>
          </template>
          </b-table>
        </b-card-body>
        <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>10">        
          <b-pagination
            class="mb-0"
            v-model="pageNo"
            :per-page="data.per_page"
            :total-rows="data.total"
          />
        </b-card-footer>
      </b-card>
      <template v-else>
        <Form :row.sync="row" v-bind="passToSub" />
      </template>
    </div>  
  </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Form from './Form.vue'
const _ = global._

export default {
  extends: GlobalVue,
  components:{Form},
  data() {
    return {
      idKey:'mp_id',
      statusKey:'mp_is_active',
      fields: [
        {
          key: 'number',
          label: '#',
        },
        {
          key: 'mp_code',
          label: 'Kode',
        },
        {
          key: 'mp_name',
          label: 'Gelar Pendidikan',
        },
        {
          key: 'mp_is_active',
          label: 'Status',
        },
        {
          key: 'action',
        },
      ],
    }
  },
  computed:{
    passToSub(){
      return _.assign(this.passToSubComp||{})
    }
  },
  mounted(){
    this.apiGet()
    if(this.$route.params.pageSlug){
      this.validateModuleRoleCrud()
    }
  },
  methods: {
    doFill(){
      this.doFilter()
    }
  },
  watch:{
    $route(){
      this.apiGet()
    },
    '$route.params.pageSlug':function(){
      this.$set(this,'filter',{})
    }
  }
}
</script>